import { ActivityIndicator } from '@troon/ui';
import { For, Match, Show, Suspense, Switch } from 'solid-js';
import { gql } from '../graphql';
import { dollarFormatter } from '../modules/number-formatting';
import type { PaymentInfoFragment } from '../graphql';
import type { JSX } from 'solid-js';

type Props = {
	fallbackContent?: JSX.Element;
	isCheckIn?: boolean;
	receipt?: PaymentInfoFragment;
	rewardPointsEarned?: number;
	supportsTroonRewards?: boolean;
};

export function PaymentInfo(props: Props) {
	return (
		<>
			<h2 class="text-lg font-semibold">Payment info</h2>

			<Suspense>
				<Show when={props.supportsTroonRewards}>
					<p class="rounded bg-brand-100 p-4 text-brand-600">
						Earn{' '}
						<Suspense>
							<Show when={props.rewardPointsEarned}>
								up to <b class="font-bold">{props.rewardPointsEarned}</b>
							</Show>
						</Suspense>{' '}
						<b class="font-bold">Troon Rewards</b> points when you pay for your round.
					</p>
				</Show>
			</Suspense>

			<Suspense fallback={<ActivityIndicator class="text-brand">Calculating…</ActivityIndicator>}>
				<table class="w-full">
					<thead class="sr-only">
						<tr>
							<th>Item</th>
							<th class="text-end font-semibold">Amount</th>
						</tr>
					</thead>
					<tbody class="">
						<For
							each={props.receipt?.items}
							fallback={
								<tr>
									<td colspan={2}>{props.fallbackContent ?? 'Select players to continue…'}</td>
								</tr>
							}
						>
							{(item) => (
								<tr class="border-b border-neutral-500">
									<td class="py-4 ">{item.label}</td>
									<td class="py-4 text-end font-semibold">{item.amount.displayValue}</td>
								</tr>
							)}
						</For>
					</tbody>
					<Show when={props.receipt}>
						<tfoot>
							<tr class="text-lg">
								<td class="pt-4 font-semibold">Total due now</td>
								<td class="pt-4 text-end font-semibold">
									<Switch>
										<Match when={props.isCheckIn}>{props.receipt?.total.displayValue}</Match>
										<Match when={!props.isCheckIn}>{dollarFormatter(props.receipt?.total.code)(0)}</Match>
									</Switch>
								</td>
							</tr>
							<Show when={!props.isCheckIn}>
								<tr>
									<td class="pb-4">Total due at check in</td>
									<td class="pb-4 text-end font-semibold">{props.receipt?.total.displayValue}</td>
								</tr>
							</Show>
						</tfoot>
					</Show>
				</table>
			</Suspense>
		</>
	);
}

gql(`
fragment PaymentInfo on Receipt {
  total {
    displayValue
    code
  }
  items {
    label
    amount {
      displayValue
      code
    }
    itemType
  }
}
`);
